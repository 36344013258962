import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-subcard-navbar',
  templateUrl: './subcard-navbar.component.html',
  styleUrls: ['./subcard-navbar.component.css']
})
export class SubcardNavbarComponent implements OnInit {
  @Input() currentComponent = this.currentComponent;
  constructor() { }

  ngOnInit() {
  }

}
