import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { SubcardService } from './shared/services/subcard.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public sessionId: string;
  constructor( private subcardService: SubcardService, private auth: AuthService, private router: Router) {
      console.log('Called Constructor');
   
   
  }
  public token: any;
  private sessionCookie: string;
  ngOnInit() {
    //this.auth.localAuthSetup();
 
   
    //this.sessionCookie = this.cookieService.get('WylieUserSessionId');
    //if (!this.sessionCookie) {
    //  this.router.navigate(['/subcard/user']);
    //}
 
  }

}
