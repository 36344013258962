import { UserDataModel } from '../modals/UserDataModel';
import { BankAccountDetailsModel } from '../modals/BankAccountDetailsModel';
import { RefundTransactionModel } from './RefundTransactionModel';

export class UserDetailsModel {
  UserData: UserDataModel;
  AmountUserIntendsToGetRefunded: string;
  BankAccountDetails: BankAccountDetailsModel;
  TransactionId: string;
  RefundTransaction: RefundTransactionModel;
}
