import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'subcard-internal-user',
  templateUrl: './subcard-internal-user.component.html',
  styleUrls: ['./subcard-internal-user.component.css']
})
export class SubcardInternalUserComponent implements OnInit {
  profileJson: string = null;

  constructor(public auth: AuthService) { }

  ngOnInit() {
    this.auth.userProfile$.subscribe(
      profile => this.profileJson = JSON.stringify(profile, null, 2)
    );
  }
  
}
