import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubcardService } from '../shared/services/subcard.service';

import { WylieUserDetailsModel } from '../shared/modals/WylieUserDetailsModel';

@Component({
  selector: 'app-subcard-admin',
  templateUrl: './subcard-admin.component.html',
  styleUrls: ['./subcard-admin.component.css']
})
export class SubcardAdminComponent implements OnInit {

  public WylieUserDetailsModel: WylieUserDetailsModel;
  constructor(private subcardService: SubcardService, private router: Router) {
    this.WylieUserDetailsModel = new WylieUserDetailsModel();
  }
  ngOnInit() {
  }

  SubmitToken(token) {
    this.subcardService.getInternalUserDetails(token).subscribe(res => {
      this.subcardService.setUserDetails(res);////behavioursubject 
      this.router.navigate(['/subcard/user']);
      
    },
      err => {
        console.log(err.status);
      },
    );

  }

  TestToken(token) {
    this.subcardService.getWylieUserDetails(token).subscribe(res => {
      this.subcardService.setWylieUserDetails(res);////behavioursubject 
      this.router.navigate(['/subcard/user']);
      this.subcardService.setWylieUserDetails(res);
      this.subcardService.getWylieSessionDetails()
        .subscribe(userDetailsData => { this.WylieUserDetailsModel = userDetailsData });
      encodeURIComponent
      window.location.href = "https://subcardrefundkr.com/subcard/user?sessionID=" +encodeURIComponent(res.sessionID);
    },
      err => {
        console.log(err.status);
      },
    );

  }
}
