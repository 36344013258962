import { Component, OnInit, Input } from '@angular/core';
import { SubcardService } from '../shared/services/subcard.service';

@Component({
  selector: 'app-subcard-user-complete-message',
  templateUrl: './subcard-user-complete-message.component.html',
  styleUrls: ['./subcard-user-complete-message.component.css']
})
export class SubcardUserCompleteMessageComponent implements OnInit {
  userDetails = {};
  CaseReference: string;
  RequestedRefundAmount: number;
  Name: string;
  CardNumber: string;
  @Input() eligibleforrefund = this.eligibleforrefund;
  @Input() homepageLink = this.homepageLink;

  constructor(private subcardService: SubcardService) {
    //behavioursubject starts
   this.subcardService.getSubmittedUserDetails()
     .subscribe(userDetails => { this.userDetails = userDetails });
    //behavioursubject ends
    this.CardNumber = this.userDetails["CardNumber"];
    this.Name = this.userDetails["Name"];
    this.RequestedRefundAmount = this.userDetails["RequestedRefundAmount"];
    this.CaseReference = this.userDetails["CaseReference"];

  }

  ngOnInit() {
  }

}
