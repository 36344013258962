import { Component, OnInit } from '@angular/core';
import { AuthNewService } from '../shared/services/authnew.service';

@Component({
  selector: 'app-subcard-internal-login',
  templateUrl: './subcard-internal-login.component.html',
  styleUrls: ['./subcard-internal-login.component.css']
})
export class SubcardInternalLoginComponent implements OnInit {

  constructor(public auth: AuthNewService) { }

  ngOnInit() {
  }

}
