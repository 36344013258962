import { Component, OnInit } from '@angular/core';
import { SubcardService } from '../shared/services/subcard.service';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-subcard-status-failed',
  templateUrl: './subcard-status-failed.component.html',
  styleUrls: ['../subcard-status-submitted/subcard-status-submitted.component.css']
})
export class SubcardStatusFailedComponent implements OnInit {

  userStatusDetails = [];
  currentComponent = "failed";
  constructor(private subcardService: SubcardService,private authService: AuthService) { }

  ngOnInit() {
    this.subcardService.getExternalUserDetails("failed").subscribe(res => {
      this.userStatusDetails = res;
    },
      err => {
        console.log(err.status);
      },
    );
  }

  logoutUser() {
    this.authService.logout();
  }
}
