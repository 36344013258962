import { Component, OnInit } from '@angular/core';
import { AuthNewService } from '../shared/services/authnew.service';
import { Router } from '@angular/router';

@Component({
  selector: 'subcard-user',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallBackComponent implements OnInit {
 

  constructor(public auth: AuthNewService, private router: Router) {
    auth.handleAuth();
    
  }

  ngOnInit() { }


  
}
