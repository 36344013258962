import { Component, OnInit } from '@angular/core';
import { SubcardService } from '../shared/services/subcard.service';
import { AuthNewService } from '../shared/services/authnew.service';

@Component({
  selector: 'app-subcard-status-submitted',
  templateUrl: './subcard-status-submitted.component.html',
  styleUrls: ['./subcard-status-submitted.component.css']
})
export class SubcardStatusSubmittedComponent implements OnInit {

  userStatusDetails = [];
  constructor(private subcardService: SubcardService, public auth: AuthNewService) { }
  currentComponent = "new";
  ngOnInit() {
    this.subcardService.getExternalUserDetails("new").subscribe(res => {
      this.userStatusDetails = res;
    },
      err => {
        console.log(err.status);
      },
    );
  }

 

}
