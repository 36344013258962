import { Component, OnInit } from '@angular/core';
import { SubcardService } from '../shared/services/subcard.service';
import { UserDetailsModel } from '../shared/modals/UserDetailsModel';
import { UserDataModel } from '../shared/modals/UserDataModel';
import { BankAccountDetailsModel } from '../shared/modals/BankAccountDetailsModel';
import { GiftSummaryModel } from '../shared/modals/GiftSummaryModel';
import { Router } from '@angular/router';
import { CryptoService } from '../shared/services/crypto.service';
import { AuthNewService } from '../shared/services/authnew.service';

@Component({
  selector: 'subcard-user',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
 

  constructor(public auth: AuthNewService) {
 
  }

  ngOnInit() { }


  
}
