import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from "@auth0/angular-jwt";
import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './shared/services/interceptor.service';
import { Observable } from 'rxjs';
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { EnvironmentUrlService } from './shared/services/environment-url.service';
import { RepositoryService } from './shared/services/repository.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { AuthService } from './shared/services/auth.service';
import { AuthNewService} from './shared/services/authnew.service';
import { CryptoService } from './shared/services/crypto.service';
import { AuthGuard } from './shared/auth-guard/auth.guard';
import { AppLoadService } from './shared/services/apploader.service';
import { JwtService } from './shared/services/jwt.service';
import { SubcardInternalUserComponent } from './subcard-internal-user/subcard-internal-user.component';
import { SubcardUserComponent } from './subcard-user/subcard-user.component';
import { SubcardLoginComponent } from './subcard-login/subcard-login.component';
import { SubcardAdminComponent } from './subcard-admin/subcard-admin.component';
import { SubcardUserCompleteMessageComponent } from './subcard-user-complete-message/subcard-user-complete-message.component';
import { SubcardStatusInprogressComponent } from './subcard-status-inprogress/subcard-status-inprogress.component';
import { SubcardStatusFailedComponent } from './subcard-status-failed/subcard-status-failed.component';
import { SubcardStatusCompletedComponent } from './subcard-status-completed/subcard-status-completed.component';
import { SubcardInternalUserTableComponent } from './subcard-internal-user-table/subcard-internal-user-table.component';
import { SubcardStatusSubmittedComponent } from './subcard-status-submitted/subcard-status-submitted.component';
import { HeaderComponent } from './header/header.component';
import { CallBackComponent } from './callback/callback.component';
import { SubcardInternalLoginComponent } from './subcard-internal-login/subcard-internal-login.component';
import { SubcardNavbarComponent } from './subcard-navbar/subcard-navbar.component';


export function init_app(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

//export function get_settings(appLoadService: AppLoadService) {
//  return () => appLoadService.getSettings();
//}

// called on every request to retrieve the token
//export function tokenGetter() {
//  var userObject = JSON.parse(sessionStorage.getItem("user"));
//  return userObject.Token;

//}


@NgModule({
  declarations: [
    AppComponent,
    SubcardInternalUserComponent,
    SubcardUserComponent,
    SubcardLoginComponent,
    SubcardAdminComponent,
    SubcardUserCompleteMessageComponent,
    SubcardStatusInprogressComponent,
    SubcardStatusCompletedComponent,
    SubcardStatusFailedComponent,
    SubcardInternalUserTableComponent,
    SubcardStatusSubmittedComponent,
    HeaderComponent,
    CallBackComponent,
    SubcardInternalLoginComponent,
    SubcardNavbarComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        //tokenGetter: tokenGetter,
        //whitelistedDomains: ["dmbpricingapi"],
        //blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    }),
    FormsModule,
    RouterModule.forRoot(
      [

        { path: 'subcard/user', component: SubcardUserComponent },
        { path: 'subcard/admin', component: SubcardAdminComponent },
        { path: 'subcard/internaluser', component: SubcardInternalUserComponent },
        { path: 'subcard/login', component: SubcardLoginComponent },
        { path: 'subcard/user-message', component: SubcardUserCompleteMessageComponent },
        { path: 'subcard/inprogress', component: SubcardStatusInprogressComponent },
        { path: 'subcard/completed', component: SubcardStatusCompletedComponent },
        { path: 'subcard/failed', component: SubcardStatusFailedComponent },
        { path: 'subcard/new', component: SubcardStatusSubmittedComponent },
        { path: 'subcard/header', component: HeaderComponent },
        { path: 'subcard/callback', component: CallBackComponent },
        { path: '', component: AppComponent },
      ])
  ],
  providers: [
    EnvironmentUrlService,
    RepositoryService,
    AuthService,
    ErrorHandlerService,
    JwtService,
    AppLoadService,
    CryptoService,
    AuthNewService,

    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: InterceptorService,
    //  multi: true
    //}
 
    //{ provide: APP_INITIALIZER, useFactory: get_settings, deps: [AppLoadService], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
