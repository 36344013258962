import { Component, OnInit, Input } from '@angular/core';
import { SubcardService } from '../shared/services/subcard.service';
import { CryptoService } from '../shared/services/crypto.service';
import { AuthNewService } from '../shared/services/authnew.service';

@Component({
  selector: 'app-subcard-internal-user-table',
  templateUrl: './subcard-internal-user-table.component.html',
  styleUrls: ['./subcard-internal-user-table.component.css']
})
export class SubcardInternalUserTableComponent implements OnInit {
  @Input() userStatusDetails = this.userStatusDetails;
  @Input() currentComponent = this.currentComponent;
  constructor(private subcardService: SubcardService, private cryptoService: CryptoService, private auth: AuthNewService) { }
  selectedname = "";
  selectedid = "";
  status = "";
  comments = "";
  selectedTransaction = {};
  errorMessage = false;
  ngOnInit() {
  }
  updateStatus(event) {
    document.getElementById("transactionDetails").style.display = 'block';
    var currentButton = event.target;
    this.selectedname = currentButton.parentNode.parentNode.querySelector('#userName').innerHTML;
    this.selectedid = currentButton.parentNode.parentNode.querySelector('#transactionId').innerHTML;
    document.getElementById("selectedUserName").innerHTML = this.selectedname;
    document.getElementById("selectedTransactionId").innerHTML = this.selectedid;
    document.getElementById("selectedCurrentStatus").innerHTML = currentButton.parentNode.parentNode.querySelector('#currentStatus').innerHTML;
    document.getElementById("selectedMobileNumber").innerHTML = currentButton.parentNode.parentNode.querySelector('#mobileNumber').innerHTML;
    if (currentButton.parentNode.parentNode.querySelector('#RequestedDate') !== null)  document.getElementById("selectedRequestedDate").innerHTML = this.displayDate(currentButton.parentNode.parentNode.querySelector('#RequestedDate').innerHTML);
    if (currentButton.parentNode.parentNode.querySelector('#ProcessedDate') !== null) document.getElementById("selectedProcessedDate").innerHTML = this.displayDate(currentButton.parentNode.parentNode.querySelector('#ProcessedDate').innerHTML);
    document.getElementById("selectedCardNumber").innerHTML = currentButton.parentNode.parentNode.querySelector('#CardNumber').innerHTML;
    if (currentButton.parentNode.parentNode.querySelector('#BankAccountName') !== null)document.getElementById("selectedBankAccountName").innerHTML = this.cryptoService.get('123456$#@$^@1ERF', currentButton.parentNode.parentNode.querySelector('#BankAccountName').innerHTML);
    if (currentButton.parentNode.parentNode.querySelector('#BankAccountNumber') !== null)document.getElementById("selectedBankAccountNumber").innerHTML = this.cryptoService.get('123456$#@$^@1ERF', currentButton.parentNode.parentNode.querySelector('#BankAccountNumber').innerHTML);
    if (currentButton.parentNode.parentNode.querySelector('#BankName') !== null)document.getElementById("selectedBankName").innerHTML = this.cryptoService.get('123456$#@$^@1ERF', currentButton.parentNode.parentNode.querySelector('#BankName').innerHTML);
    document.getElementById("selectedComments").innerHTML = currentButton.parentNode.parentNode.querySelector('#Comments').innerHTML;
    this.comments = currentButton.parentNode.parentNode.querySelector('#Comments').innerHTML;
    if (currentButton.parentNode.parentNode.querySelector('#ReversalCaseNumber') !== null) document.getElementById("selectedReversalCaseNumber").innerHTML = currentButton.parentNode.parentNode.querySelector('#ReversalCaseNumber').innerHTML;
    if (currentButton.parentNode.parentNode.querySelector('#ReversalTransactionId') !== null) document.getElementById("selectedReversalTransactionId").innerHTML = currentButton.parentNode.parentNode.querySelector('#ReversalTransactionId').innerHTML;
    document.getElementById("selectedGiftBalance").innerHTML = currentButton.parentNode.parentNode.querySelector('#GiftBalance').innerHTML;
    document.getElementById("selectedAmountIntendsToRefund").innerHTML = currentButton.parentNode.parentNode.querySelector('#AmountIntendsToRefund').innerHTML;
    document.getElementById("selectedRedeemed").innerHTML = currentButton.parentNode.parentNode.querySelector('#Redeemed').innerHTML;
    document.getElementById("selectedAccured").innerHTML = currentButton.parentNode.parentNode.querySelector('#Accrued').innerHTML;
    
  }

  displayDate(dates) {
    var monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    var refdate = new Date(dates.replace(/(\d{2})[-/](\d{2})[-/](\d+)/, "$2/$1/$3"));
    var refdateString = refdate.getDate() + ' ' + monthNames[refdate.getMonth()] + ' ' + refdate.getFullYear();
    return refdateString;
  }

  changestatus(value,type) {
    if (type === "status")this.status = value;
    if (type === "comments") this.comments = value;
  }

  submitStatusDetails(subcardForm) {
    if (!this.auth.tokenValid) {
      this.errorMessage = true;
      this.auth.logout();
    }
    for (var i = 0; i < this.userStatusDetails.length; i++) {
      if (this.userStatusDetails[i].TransactionId === this.selectedid) {
        this.selectedTransaction = this.userStatusDetails[i];
        this.userStatusDetails[i]["RefundTransaction"]["RefundStatus"] = this.status;
        this.userStatusDetails[i]["RefundTransaction"]["Comments"] = this.comments;
      }
    }
    
    this.subcardService.submitStatusDetails(this.selectedTransaction, this.currentComponent).subscribe(res => {
      setTimeout(function () {
        if (res.ErrorCode === 0) {//success
          document.getElementById("successInfo").style.display = 'block';
        } else if (res.ErrorCode === 900001 || res.ErrorCode === 900002 || res.ErrorCode === 900003) {//server error from simplicity, dont move to failed
          document.getElementById("serverErrorInfo").style.display = 'block'; // error due to account issue, move to failed
        } else {
          document.getElementById("errorInfo").style.display = 'block';
        }
      }, 5000);      

    },
      err => {
        document.getElementById("errorInfo").style.display = 'block';
      },
    );
  }
}
