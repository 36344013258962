import { GiftSummaryModel } from "./GiftSummaryModel";

export class UserDataModel {
  ProfileID: string;
  Name: string;
  GiftBalance: number;
  CardNumber: string;
  MobileNumber: string;
  GiftSummary: GiftSummaryModel;
}
