import { HttpClient } from '@angular/common/http';
import 'rxjs';
import { CONFIG } from '../../app.config';

import { Injectable } from '@angular/core';

@Injectable()
export class AppLoadService {
  private baseUrl: string = CONFIG.apiURL;
  constructor(private httpClient: HttpClient) {
  }

  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(`initializeApp:: inside promise`);

      setTimeout(() => {
        console.log(`initializeApp:: inside setTimeout`);
        // doing something

        resolve();
      }, 3000);
    });
  }

  getSettings(): Promise<any> {
    console.log(`getSettings:: before http.get call`);

    const promise = this.httpClient.get('https://' + this.baseUrl +'/api/subcard/getusercarddetails')
      .toPromise()
      .then(

        subcardUser => {
          console.log(`Settings from API: `, subcardUser);
          sessionStorage.setItem("user",JSON.stringify(subcardUser));

        //if (user != null) {

        //  this.auth.loggedIn = true;
        //}

          return subcardUser;
      });

    return promise;
}
}
