// src/app/auth/auth.config.ts
import { ENV } from '../env.config';

interface AuthConfig {
  CLIENT_ID: string;
  CLIENT_DOMAIN: string;
  AUDIENCE: string;
  REDIRECT: string;
  SCOPE: string;
};

export const AUTH_CONFIG: AuthConfig = {
  CLIENT_ID: 'f5hJKIGo5uTRE7tjmHjKoCbX2fgX7M1U',
  CLIENT_DOMAIN: 'ipca.au.auth0.com', // e.g., you.auth0.com
  AUDIENCE: 'https://subcardrefundkr.com', // e.g., http://localhost:8083/api/
  REDIRECT: `${ENV.BASE_URI}/subcard/callback`,
  SCOPE: 'openid profile'
};
