import { Component, OnInit } from '@angular/core';

import { AuthNewService } from '../shared/services/authnew.service';
@Component({
  selector: 'app-subcard-login',
  templateUrl: './subcard-login.component.html',
  styleUrls: ['./subcard-login.component.css']
})
export class SubcardLoginComponent implements OnInit {

  constructor(public auth: AuthNewService) { }

  ngOnInit() {}

  //loginUser() {
  //    this.authService.login("/subcard/inprogress");
  //    //logged in. you can get the user profile like this:
  //    const user = this.authService.userProfile$;
  //    console.log(user);
   
  //}
}
