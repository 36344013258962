import { Component, OnInit } from '@angular/core';
import { SubcardService } from '../shared/services/subcard.service';
import { UserDetailsModel } from '../shared/modals/UserDetailsModel';
import { UserDataModel } from '../shared/modals/UserDataModel';
import { BankAccountDetailsModel } from '../shared/modals/BankAccountDetailsModel';
import { GiftSummaryModel } from '../shared/modals/GiftSummaryModel';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptoService } from '../shared/services/crypto.service';
import { RecentGiftSummaryModel } from '../shared/modals/RecentGiftSummaryModel';


@Component({
  selector: 'subcard-user',
  templateUrl: './subcard-user.component.html',
  styleUrls: ['./subcard-user.component.css']
})
export class SubcardUserComponent implements OnInit {
  public UserDetailsModel: UserDetailsModel;
  public UserDataModel: UserDataModel;
  public BankAccountDetailsModel: BankAccountDetailsModel;
  public GiftSummaryModel: GiftSummaryModel;
  public sessionID: string;
  isNumberSame = true;
  userName: string;
  ProfileID: string;
  GiftBalance: number;
  CardNumber: string;
  MobileNumber: string;
  OpeningBalance: number;
  EligibleAmountForRefund: number;
  Accrued: number;
  Redeemed: number;
  Expired: number;
  AmountUserIntendsToGetRefunded: string;
  BankAccountNumber: string;
  BankName: string;
  AccountName: string;
  amountUserEntered: string;
  eligibleforrefund: boolean = true;
  isLargerthanGiftBalance: boolean = false;
  userDetailsData: {};
  isSubmitted = false;
  numberlengtherror = false;
  BankList = ['KEB하나은행', '국민은행', '기업은행', '농협', '단위농협', '카카오뱅크', '수협중앙회', '신한은행', '우리은행', '우체국', '한국스탠다드차타드', '한국씨티은행', '산업은행', '대구은행', '부산은행', '광주은행', '제주은행', '전북은행', '경남은행', '새마을금고', '신용협동조합', '상호신용금고', '홍콩샹하이은행', '도이치은행', '제이피모간체이스은행', 'BOA', '산림조합중앙회', '케이뱅크', '유안타증권', '현대증권', '미래에셋대우', '삼성증권', '한국투자증권', '우리투자증권', '교보증권', '하이투자증권', 'HMC투자증권', '키움증권', '이트레이드증권', 'SK증권', '대신증권', '솔로몬투자증권', '하나대투증권', '신한금융투자', '동부증권', '유진투자증권', '메리츠증권', '부국증권', '신영증권', 'LIG투자증권']
  giftBalanceZero = false;
  homepageLink: string;

  constructor(private route: ActivatedRoute, private subcardService: SubcardService, private router: Router, private cryptoService: CryptoService
   ) {
    this.UserDetailsModel = new UserDetailsModel();
    this.UserDetailsModel['UserData'] = new UserDataModel();
    this.UserDetailsModel['BankAccountDetails'] = new BankAccountDetailsModel();
    this.UserDetailsModel['UserData']['GiftSummary'] = new GiftSummaryModel();
    this.UserDetailsModel['UserData']['GiftSummary']['Recent'] = new RecentGiftSummaryModel();
    //if (this.cookieService.get('WylieUserSessionId')) {
    //  this.sessionID = this.cookieService.get('WylieUserSessionId');
    //}
    //else {
      //behavioursubject starts
      this.route.queryParams.subscribe(params => {
        this.sessionID = params['sessionID'];
       
       this.sessionID = decodeURIComponent(this.sessionID);
      });
    }

  

  ngOnInit() {
    //this.sessionID = sessionStorage.getItem("sessionID");
    this.subcardService.getSubcardUserDetails(this.sessionID).subscribe(res => {
      this.subcardService.setUserDetails(res);////behavioursubject 
      this.subcardService.getUserDetails()
        .subscribe(userDetailsData => { this.userDetailsData = userDetailsData });
      //behavioursubject ends
      this.userName = this.userDetailsData["Data"]["Name"];
      this.ProfileID = this.userDetailsData["Data"]["ProfileID"];
      this.GiftBalance = this.userDetailsData["Data"]["GiftBalance"];
      this.CardNumber = this.userDetailsData["Data"]["CardNumber"];
      this.MobileNumber = this.userDetailsData["Data"]["MobileNumber"];
      this.OpeningBalance = this.userDetailsData["Data"]["GiftSummary"]["Recent"].OpeningBalance;
      this.Accrued = this.userDetailsData["Data"]["GiftSummary"]["Recent"].Accrued;
      this.Redeemed = this.userDetailsData["Data"]["GiftSummary"]["Recent"].Redeemed;
      this.Expired = this.userDetailsData["Data"]["GiftSummary"]["Recent"].Expired;
      //If accrued is under 10,000 - he should have redeemed atleast 80% of the accrued to be eligible for a refund
      //If accrued is over 10, 000 - he should have redeemed atleast 60 % of the accrued to be eligible for a refund
      let eligibleAmt80 = this.OpeningBalance * 0.8;
      let eligibleAmt60 = this.OpeningBalance * 0.6;

      if (this.OpeningBalance <= 10000) {
       
        if (this.Redeemed >= eligibleAmt80) {
          this.eligibleforrefund = true;
          this.EligibleAmountForRefund = this.GiftBalance;
        }
        else {
          this.EligibleAmountForRefund = 0;
          this.eligibleforrefund = false;
        }
      }

      else if (this.OpeningBalance > 10000)
         {
      
        if (this.Redeemed >= eligibleAmt60) {
          this.eligibleforrefund = true;
          this.EligibleAmountForRefund = this.GiftBalance;

        }

        else {
          this.EligibleAmountForRefund = 0;
          this.eligibleforrefund = false;
        }
      }
      if (this.GiftBalance === 0) {
        this.EligibleAmountForRefund = this.GiftBalance;
        this.eligibleforrefund = false; this.giftBalanceZero = true;
      }
//else if (this.Accrued === 0 || this.Redeemed === 0) this.eligibleforrefund = false;
      //else this.eligibleforrefund = false;

      //this.homepageLink = "https://www-dev.subway.co.kr/mypage/subcard";
     this.homepageLink = "https://www.subway.co.kr/mypage/subcard";
    },
      err => {
        console.log(err.status);
      },
    );
  }

  checkAccountNumber(accountNumber, confirmAccountNumber) {
    this.isNumberSame = (accountNumber === confirmAccountNumber) ? true : false;
    this.numberlengtherror = (accountNumber.length < 10 || accountNumber.length > 15) ? true : false;

  }

  compareGiftbalance(AmountUserIntendsToGetRefunded) {
    this.isLargerthanGiftBalance = (AmountUserIntendsToGetRefunded > this.GiftBalance) ? true : false;
    if (this.isLargerthanGiftBalance == true) (<HTMLInputElement>document.getElementById('AmountUserIntendsToGetRefunded')).value = '';
  }

  dataChanged(item) {
    this.BankName = item.target.value;
    if (this.BankName.indexOf(':') > -1) this.BankName = this.BankName.slice(this.BankName.indexOf(":") + 1, this.BankName.length).trim();
  }

  submitUserDetails(userform) {
    console.log(userform.value)
    console.log(userform.valid)
    this.UserDetailsModel["UserData"].Name = this.userName;
    this.UserDetailsModel['UserData'].ProfileID = this.ProfileID;
    this.UserDetailsModel['UserData'].GiftBalance = this.GiftBalance;
    this.UserDetailsModel['UserData'].CardNumber = this.CardNumber;
    this.UserDetailsModel['UserData'].MobileNumber = this.MobileNumber;
    this.UserDetailsModel['UserData']['GiftSummary']['Recent'].Accrued = this.Accrued;
    this.UserDetailsModel['UserData']['GiftSummary']['Recent'].Redeemed = this.Redeemed;
    this.UserDetailsModel['UserData']['GiftSummary']['Recent'].Expired = this.Expired;
    this.UserDetailsModel['AmountUserIntendsToGetRefunded'] = userform.value.AmountUserIntendsToGetRefunded;

    //encrypt the data


    this.UserDetailsModel['BankAccountDetails'].BankAccountNumber = this.cryptoService.set('123456$#@$^@1ERF', userform.value.BankAccountNumber);
    this.UserDetailsModel['BankAccountDetails'].BankName = this.cryptoService.set('123456$#@$^@1ERF', this.BankName);
    this.UserDetailsModel['BankAccountDetails'].AccountName = this.cryptoService.set('123456$#@$^@1ERF', userform.value.AccountName);

    this.subcardService.submitUserDetails(this.UserDetailsModel).subscribe(res => {
      this.subcardService.setSubmittedUserDetails(res);////behavioursubject
      this.isSubmitted = true;
    },
    err => {
      document.getElementById("errorInfo").style.display = 'block';
      },
    );
  }
}
