import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CONFIG } from '../../app.config';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDetailsModel } from '../modals/UserDetailsModel';
import { SubmittedUserDetailsModel } from '../modals/SubmittedUserDetailsModel';
import { AuthNewService } from './authnew.service';
import { WylieUserDetailsModel } from '../modals/WylieUserDetailsModel';
import { HttpCustomParameterCodec } from '../HttpCustomParameterCodec';

@Injectable({
  providedIn: 'root'
})
export class SubcardService {
  private baseUrl: string = CONFIG.apiURL;
  private userdataSubject: BehaviorSubject<UserDetailsModel> = new BehaviorSubject<UserDetailsModel>(null);
  private submittedDataSubject: BehaviorSubject<SubmittedUserDetailsModel> = new BehaviorSubject<SubmittedUserDetailsModel>(null);
  private statusUrl = "";
  private token: string;
  private sessionCookie: string;
  private wylieuserDataSubject: BehaviorSubject<WylieUserDetailsModel> = new BehaviorSubject<WylieUserDetailsModel>(null);
  constructor(private http: HttpClient, private auth: AuthNewService) {


  }

  getInternalUserDetails(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post('https://' + this.baseUrl + '/api/subcard/loginuserdev', { 'UserToken': token, observe: 'response' })
      .pipe(map((res: any) => res));
  }

  getWylieUserDetails(token) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlFrVkVRakU0UkVJeE16QTJORFV6T1VKRk5qUTFOamhHTlVZd1FUUkVNRVJCTXprNU1FUXdOZyJ9.eyJpc3MiOiJodHRwczovL2lwY2EuYXUuYXV0aDAuY29tLyIsInN1YiI6IlA5blVhaWFpUjdMS3F1Nzk1YTA2S1dxbkxPUW9BbHBmQGNsaWVudHMiLCJhdWQiOiJodHRwczovL3N1YmNhcmRrci1yZWZ1bmQuYXp1cmV3ZWJzaXRlcy5uZXQiLCJpYXQiOjE1OTU0MDAyNjksImV4cCI6MTU5NTQ4NjY2OSwiYXpwIjoiUDluVWFpYWlSN0xLcXU3OTVhMDZLV3FuTE9Rb0FscGYiLCJndHkiOiJjbGllbnQtY3JlZGVudGlhbHMifQ.dv_ymYUXCe_ZJGm7p8k_Uxeh8ABTRYYq_fuWZLRIVMqnUl3B8I1XqzN_l1ZvwwstV3MCu2ciAgTRHCImwoP8T6wXwQMssKonAwGxfxuRUkiSXof6UznYCZ1AuI8K6UnyRyVxcj2K74RI3tGlorg_ElS5nqxXaKBcm-Wa9By_ZjfL29PmpSL7Jc0X_9quVLVHA5ClhAsxN7W94-dJJk8mbJBYzR5jeS_SySm7TwxkZWQysJhTugiMu4g5w1i3zvzgn-Yfc7U_oyi8RfkCBoh8TTSieiis4kjoTc3sW28fU8MP20qo25-ctpoCPoJygiNdGyqS8gmfVbNVZvAfJL1Owg'
      })
    };
    return this.http.post('https://' + this.baseUrl + '/api/subcard/loginuserdev', { 'UserToken': token, observe: 'response' }, httpOptions)
      .pipe(map((res: any) => res));
  }

  getSubcardUserDetails(sessionCookie) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    //const params = new HttpParams({ encoder: new HttpCustomParameterCodec() });
    

    return this.http.post('https://' + this.baseUrl + '/api/subcard/getusercarddetails', { "SessionId": sessionCookie })
      .pipe(map((res: any) => res));
  }

  getExternalUserDetails(status) {
    if (this.auth.tokenValid)
      this.token = sessionStorage.getItem("access_token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
    };
    return this.http.post('https://' + this.baseUrl + '/api/subcard/getalltransactions/' + status, {}, httpOptions)
      .pipe(map((res: any) => res));
  }

  //behavioursubject starts
  getSubmittedUserDetails(): Observable<SubmittedUserDetailsModel> {
    return this.submittedDataSubject.asObservable();
  }
  public setSubmittedUserDetails(submitteddata: SubmittedUserDetailsModel): void {
    this.submittedDataSubject.next(submitteddata);

  }

  getUserDetails(): Observable<UserDetailsModel> {
    return this.userdataSubject.asObservable();
  }
  public setUserDetails(message: UserDetailsModel): void {
    this.userdataSubject.next(message);

  }


  getWylieSessionDetails(): Observable<WylieUserDetailsModel> {
    return this.wylieuserDataSubject.asObservable();
  }

  public setWylieUserDetails(message: WylieUserDetailsModel): void {
    this.wylieuserDataSubject.next(message);

  }
  //behavioursubject ends

  submitUserDetails(userDetailsModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<UserDetailsModel>('https://' + this.baseUrl + '/api/subcard/submituserdetails', userDetailsModel, httpOptions)
      .pipe(map((res: any) => res));
  }

  submitStatusDetails(UserDetailsModel, status) {
    if (this.auth.tokenValid)
      this.token = sessionStorage.getItem("access_token");
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
    };
    if (status === 'new') this.statusUrl = 'https://' + this.baseUrl+'/api/subcard/changerefundstatus/new';
    if (status === 'inprogress') this.statusUrl = 'https://' + this.baseUrl+'/api/subcard/completerefundtransaction/inprogress';
    return this.http.post<UserDetailsModel>(this.statusUrl, UserDetailsModel, httpOptions)
      .pipe(map((res: any) => res));
  }
}
